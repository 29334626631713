import React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { linkResolver } from 'utils/link-resolver'
import { prepareGrid } from 'utils/portfolio-grid'
import PortfolioPageTpl from 'templates/portfolio/page'

function PortfolioPage({
	data: {
		prismicPortfolioPage: {
			data: { heading, paragraph, grid },
		},
		allPrismicProject: { nodes: publishedCaseStudies },
	},
}) {
	const content = {
		category: 'All',
		grid: prepareGrid(
			grid,
			publishedCaseStudies.map(({ uid }) => uid)
		),
		publishedCaseStudies: publishedCaseStudies.map(({ uid }) => uid),
		top: { heading, paragraph },
	}

	return <PortfolioPageTpl {...content} />
}

export default withPrismicPreview(PortfolioPage, [
	{
		repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
		linkResolver,
	},
])

export const query = graphql`
	fragment GridItemFragment on PrismicPortfolioGridItemDataType {
		image {
			alt
			url
			dimensions {
				height
				width
			}
		}
		project {
			...ProjectAttributesFragment
		}
		video {
			url
		}
	}
	fragment PortfolioPageFragment on PrismicPortfolioPageDataType {
		heading
		paragraph {
			richText
		}
		grid {
			is_visible
			vertical_index
			grid_item_1 {
				document {
					... on PrismicPortfolioCta {
						id
						type
						data {
							first_line
							second_line
							button_link
							button_text
							image {
								alt
								dimensions {
									height
									width
								}
								url
							}
						}
					}
					... on PrismicPortfolioGridItem {
						id
						type
						data {
							...GridItemFragment
						}
					}
				}
			}
			grid_item_2 {
				document {
					... on PrismicPortfolioGridItem {
						id
						type
						data {
							...GridItemFragment
						}
					}
				}
			}
			grid_item_3 {
				document {
					... on PrismicPortfolioGridItem {
						id
						type
						data {
							...GridItemFragment
						}
					}
				}
			}
		}
	}
	query PortfolioAllQuery {
		prismicPortfolioPage(uid: { eq: "all" }) {
			_previewable
			data {
				...PortfolioPageFragment
			}
		}
		allPrismicProject(filter: { data: { is_published: { eq: true } } }) {
			nodes {
				uid
			}
		}
	}
`
